.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

  .NoDecoration, .NoDecoration:hover, .NoDecoration img, .NoDecoration img:hover, .NoDecoration div, .NoDecoration div:hover {
    text-decoration: none;
    color: black;
}
.no-bullets {
  list-style-type: none;
}

.div-body{
  min-height: 88vh;
  min-height: 85svh;
    display: flex;
    flex-direction: column;
}

.footer{
  margin-top: auto;
  max-height: 15vh;
  max-height: 15svh;
}

.EventTile-image{
  width: 100%;
    height: 35vw;
    max-height: 500px;
    object-fit: cover;
}

.display-flex{
  display: flex;
}
.table-space{
  padding-bottom: 10px !important;
}
.sign-up-form-container{
  min-height: 10vh;
}

.EventTile-map{
  width: 100%;
  max-height: 500px;
  height: 35vw;
}
 .ContentLimitedWidth{
  max-width:1218px ;
  width: 100%;
  margin: 0px auto 0px auto;


 }
.HelpWidget{
  
    position: fixed;
    top: 95%;
    right: 0;
    z-index: 100;
}

.map { height: 60vh; }

.leaflet-container{
  height: 100%;
}

.HelpWidget-popover{
  position: fixed; 
  top: 5%;
  inset: 0px 0px auto auto; 
  margin: 0px; transform: translate3d(-258.816px, 2655.74px, 0px);
}

.HelpWidget-popover-arrow{
  position: absolute; 
  transform: translate3d(0px, 18.432px, 0px); 
  top: 0px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
